<template>
  <div v-if="dataLoaded">
    <v-container class="pa-0">
      <app-view-toolbar
        :title="title"
        :showBackButton="true"
        :buttons="toolbarButtons"
        @onButton="onToolbarButton">
      </app-view-toolbar>
    </v-container>
    <v-container class="mt-4 responsive-container-class">
      <v-form ref="form" lazy-validation>
        <v-select
          label="Scen"
          placeholder="Välj scen"
          :items="selectableStages"
          v-model="formValues.epc_id_est"
          item-value="est_id"
          item-text="est_str_name"
          dense
          outlined
          persistent-placeholder
          background-color="white"
          :rules="[rules.requiredNumber]">
        </v-select>
        <v-row>
          <v-col cols="12" sm="6">
            <v-select
              label="Från dag"
              placeholder="Välj dag"
              :items="selectableDays"
              v-model="formValues.epc_dat_start_DATE"
              item-value="value"
              item-text="label"
              dense
              outlined
              persistent-placeholder
              background-color="white"
              :rules="[rules.required]">
            </v-select>
          </v-col>
          <v-col cols="12" sm="6">
            <app-time-input
              label="Från klockan"
              placeholder="HH:mm"
              v-model="formValues.epc_dat_start_TIME"
              :rules="[rules.required, rules.time]" />
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6">
            <v-select
              label="Till dag"
              placeholder="Välj dag"
              :items="selectableDays"
              v-model="formValues.epc_dat_end_DATE"
              item-value="value"
              item-text="label"
              dense
              outlined
              persistent-placeholder
              background-color="white"
              :rules="[rules.required]">
            </v-select>
          </v-col>
          <v-col cols="12" sm="6">
            <app-time-input
              label="Till klockan"
              placeholder="HH:mm"
              v-model="formValues.epc_dat_end_TIME"
              :rules="[rules.required, rules.time]" />
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-btn
              v-if="!isCreate"
              outlined
              color="red"
              type="button"
              class="mt-4"
              @click="onDelete"
              small>
              <v-icon left>mdi-trash-can-outline</v-icon>
              Ta bort slot
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </v-container>

    <app-confirm
      :show="showDeleteConfirm"
      title="Ta bort spelslotten?"
      @onYes="onDeleteConfirm"
      @onNo="showDeleteConfirm = false">
    </app-confirm>
  </div>
</template>

<script>

import { onMounted, reactive, ref, watch } from '@vue/composition-api';
import useCore from '../../helpers/core';
import useValidation from '../../helpers/validation'
import { createDateTime, splitDateTime } from '../../helpers/date';
import ViewToolbar from '../../components/ViewToolbar.vue';
import Confirm from '../../components/Confirm.vue';
import TimeInput from '../../components/TimeInput.vue';

export default {
  name: 'eventPerformancePut',
  props: {
    evnId: {
      type: Number,
      required: true,
    },
    id: {
      type: Number,
      required: false,
    }
  },
  components: {
    'app-view-toolbar': ViewToolbar,
    'app-confirm': Confirm,
    'app-time-input': TimeInput,
  },
  setup({ evnId, id }) {
    const router = require('@/router/router').default;
    const { callApi, showMessage } = useCore();
    const { rules } = useValidation();

    const dataLoaded = ref(false);
    const showDeleteConfirm = ref(false);

    const isCreate = !id;
    const title = ref('Ny spelning');
    const form = ref(null);
    const selectableStages = ref([]);
    const selectableDays = ref([]);

    // Data object
    const formValues = reactive({
      epc_id_est: null,
      epc_id_epr: null,
      epc_dat_start_DATE: '',
      epc_dat_start_TIME: '',
      epc_dat_end_DATE: '',
      epc_dat_end_TIME: '',
    });

    // Actions
    const onSave = () => {
      // Invalid? Exit
      const formIsValid = form.value.validate();

      if (!formIsValid) {
        showMessage('Formuläret är inte komplett ifyllt');
        return;
      }

      // Massage data
      const epc_dat_start = createDateTime(
        formValues.epc_dat_start_DATE,
        formValues.epc_dat_start_TIME,
      );
      const epc_dat_end = createDateTime(
        formValues.epc_dat_end_DATE,
        formValues.epc_dat_end_TIME,
      );

      const data = {
        epc_id_est: formValues.epc_id_est,
        epc_dat_start,
        epc_dat_end,
      };

      // Submit Create/Update
      const method = isCreate ? 'post' : 'put';
      const path = isCreate
        ? `/cms/events/${evnId}/performances`
        : `/cms/events/${evnId}/performances/${id}`;
      
      callApi({ method, path, data })
        .then(() => {
          showMessage('Spelslot har sparats');
          router.replace({ name: 'eventPerformance', params: { evnId } });
        })
        .catch((err) => {
          return err;
        });
    };

    const onDelete = () => {
      showDeleteConfirm.value = true;
    }
    const onDeleteConfirm = () => {
      callApi({ method: 'delete', path: `/cms/events/${evnId}/performances/${id}` })
        .then(() => {
          showMessage('Spelslot har tagits bort');
          router.replace({ name: 'eventPerformance', params: { evnId } });
        })
        .catch((err) => {
          return err;
        });
    };

    // Toolbar actions
    const toolbarButtons = [{ icon: 'check', name: 'Spara', fn: onSave }];
    const onToolbarButton = (button) => button.fn();

    // Lifecycle-methods
    const doOnMounted = () => {
      callApi({
        method: 'get',
        path: `/cms/events/${evnId}/performances/${id}`,
      })
        .then((res) => {
          dataLoaded.value = true;
          selectableStages.value = res._.selectableStages;
          selectableDays.value = res._.selectableDays;

          // Record found; Populate form
          if (!res.epc.noRecord) {
            title.value = `Redigera spelning (${res.epc.epr_str_name || ''})`;
            formValues.epc_id_est = res.epc.epc_id_est;
            formValues.epc_id_epr = res.epc.epcid_epr;

            const [epc_dat_start_DATE, epc_dat_start_TIME] = splitDateTime(res.epc.epc_dat_start);

            formValues.epc_dat_start_DATE = epc_dat_start_DATE;
            formValues.epc_dat_start_TIME = epc_dat_start_TIME;

            const [epc_dat_end_DATE, epc_dat_end_TIME] = splitDateTime(res.epc.epc_dat_end);
            formValues.epc_dat_end_DATE = epc_dat_end_DATE;
            formValues.epc_dat_end_TIME = epc_dat_end_TIME;
          }

          // We set up watcher here since we dont want it to start listening on changes until after data has been loaded
          watch(() => formValues.epc_dat_start_DATE, (newValue) => {
            if (newValue ) {
              formValues.epc_dat_end_DATE = newValue;
            }
          });
        })
        .catch((err) => {
          console.log(err);
          router.replace({ name: 'eventPerformance', params: { evnId } });
          return err;
        });
    };
    onMounted(doOnMounted);

    return {
      showDeleteConfirm,
      // Data
      title,
      dataLoaded,
      isCreate,
      form,
      rules,
      formValues,
      selectableDays,
      selectableStages,
      // Actions
      toolbarButtons,
      onToolbarButton,
      onDeleteConfirm,
      onDelete,
    };
  },
};
</script>

<style>
</style>