// TODO: Remove this entire file. we should instead do this in backend


const DateTime = require('luxon').DateTime; 

export const createDateTime = (date, time) => {
  const _date = DateTime.fromISO(date);
  const _time = DateTime.fromISO(time);

  // If date is not valid neither time should be set; Set empty
  if (!_date.isValid) {
    return null;
  }

  // Date is set but time not valid; Set 00:00:0:000
  if (!_time.isValid) {
    return _date.set({
      hour: 0,
      minute: 0,
      second: 0,
      millisecond: 0,
    }).toISO();
  }

  // Both date and time is set
  return _date.set({
    hour: _time.hour,
    minute: _time.minute,
    second: 0,
    millisecond: 0,
  });
};

export const splitDateTime = (dateTime) => {
  let _date = null;
  let _time = null;
  if (typeof dateTime === 'string') {
    dateTime = DateTime.fromISO(dateTime);
  }
  if (dateTime.isValid) {
    _date = dateTime.toISODate();
    _time = dateTime.toISOTime({
      includePrefix: false,
      extendedZone: false,
      includeOffset: false,
      suppressSeconds: true,
      suppressMilliseconds: true,
    });
  }
  return [_date, _time];
};
